<template>
  <div style="width:100%;height:100%">
    <div id="maps"></div>
    <div class="headers">
      <el-button type="primary" @click="goback">返回</el-button>
      <span>请选择时间段:</span>
      <el-date-picker
          :editable="false"
        style="margin-left: -10px"
        v-model="searchTime"
        type="datetimerange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-button type="primary" :disabled="startBtn" @click="start"
        >开始/继续</el-button
      >
      <el-button type="primary" @click="pause">暂停</el-button>
      <el-button type="primary" @click="stop">停止</el-button>

      <el-button class="electronic">电子围栏</el-button>
    </div>
    <!-- 弹框 -->
    <div class="MessageBox">
      <ul>
        <li>
          <span>设备名称：</span><span>{{ newList.storageName }}</span>
        </li>
        <li>
          <span>设备序列号：</span><span>{{ newList.deviceAddress }}</span>
        </li>
        <li>
          <span>设备速度：</span><span>{{ newList.speed }}km/h</span>
        </li>
        <li>
          <span>设备方向：</span><span>{{ newList.direction }}</span>
        </li>
        <li>
          <span>设备状态：</span><span>{{ newList.isRun }}</span>
        </li>
        <li>
          <span>定位方式：</span><span>{{ newList.locationType }}</span>
        </li>
        <li>
          <span>记录时间：</span><span>{{ newList.dataTime }}</span>
        </li>
        <li>
          <span>最后记录时间：</span><span>{{ maxTime }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getStorageTrajectory } from "../../api/FacilityBack";
let timer;
let index = 0;
export default {
  data() {
    return {
      path: [],
      pathList: [],
      speed: 100, //速度
      lushu: {}, //路书
      mycaiId: "",
      myMap: {}, //地图
      deviceAddress: "", //设备地址码
      searchTime: {}, // 时间选择器
      center: {}, //中心点位置
      startBtn: false,
      newList: {},
      maxTime: "" // 最后时间
    };
  },
  created() {
    this.deviceAddress = this.$route.query.deviceAddress;
    this.getList();
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 200);
  },
  watch: {
    newList: {
      deep: true,
      handler: function(newVal, oldVal) {
        this.newList = newVal;
      }
    },
  },
  methods: {
    init() {
      let { lng, lat } = this.center;
      var map = new BMap.Map("maps");
      var point = new BMap.Point(lng, lat);
      map.centerAndZoom(point, 17);
      map.enableScrollWheelZoom(true);
      //   var marker = new BMap.Marker(point); // 创建标注
      //   map.addOverlay(marker); // 将标注添加到地图中
      var points = [];
      for (var i = 0; i < this.path.length; i++) {
        points.push(new BMap.Point(this.path[i].lng, this.path[i].lat));
      }
      // console.log(points);
      var polyLine = new BMap.Polyline(points, {
        enableEditing: false,
        strokeColor: "red", //设置颜色
        strokeWeight: 5 //宽度
        // strokeOpacity: 0.5 //透明度
      });
      map.addOverlay(polyLine);
      var that = this;
      //   出现轨迹
      BMapLib.LuShu.prototype._move = function(initPos, targetPos, effect) {
        var pointsArr = [initPos, targetPos]; //点数组
        var me = this,
          //当前的帧数
          currentCount = 0,
          //步长，
          timer = 10,
          // 米/秒
          step = this._opts.speed / (1000 / timer),
          //初始坐标
          init_pos = this._projection.lngLatToPoint(initPos),
          //获取结束点的(x,y)坐标
          target_pos = this._projection.lngLatToPoint(targetPos),
          //总的步长
          count = Math.round(me._getDistance(init_pos, target_pos) / step);
        // console.log("init_pos", init_pos);
        var goPoints = JSON.parse(window.localStorage.getItem("goPoints"));
        var goPointsList = {};
        // console.log(initPos);
        goPoints.forEach(item => {
          if (item.lng == initPos.lng && item.lat == initPos.lat) {
            goPointsList = item;
            window.localStorage.setItem(
              "goPointsList",
              JSON.stringify(goPointsList)
            );
            console.log(goPointsList);
            that.newList = goPointsList;
            if (that.newList.isRun == "0") {
              that.newList.isRun = "停止";
            } else {
              that.newList.isRun = "运动";
            }
            if (that.newList.locationType == "0") {
              that.newList.locationType = "wifi定位";
            } else {
              that.newList.locationType = "gps定位";
            }
          }
        });
        //显示折线 syj201607191107
        //如果想显示小车走过的痕迹，放开这段代码就行
        // this._map.addOverlay(
        //   new BMap.Polyline(pointsArr, {
        //     strokeColor: "#111",
        //     strokeWeight: 5,
        //     strokeOpacity: 0.5
        //   })
        // ); // 画线
        //如果小于1直接移动到下一点
        if (count < 1) {
          me._moveNext(++me.i);
          return;
        }
        me._intervalFlag = setInterval(function() {
          //两点之间当前帧数大于总帧数的时候，则说明已经完成移动
          if (currentCount >= count) {
            clearInterval(me._intervalFlag);
            //移动的点已经超过总的长度
            if (me.i > me._path.length) {
              return;
            }
            //运行下一个点
            me._moveNext(++me.i);
          } else {
            currentCount++;
            var x = effect(init_pos.x, target_pos.x, currentCount, count),
              y = effect(init_pos.y, target_pos.y, currentCount, count),
              pos = me._projection.pointToLngLat(new BMap.Pixel(x, y));
            //设置marker
            if (currentCount == 1) {
              var proPos = null;
              if (me.i - 1 >= 0) {
                proPos = me._path[me.i - 1];
              }
              if (me._opts.enableRotation == true) {
                me.setRotation(proPos, initPos, targetPos);
              }
              if (me._opts.autoView) {
                if (!me._map.getBounds().containsPoint(pos)) {
                  me._map.setCenter(pos);
                }
              }
            }
            //正在移动
            me._marker.setPosition(pos);
            //设置自定义overlay的位置
            me._setInfoWin(pos);
          }
        }, timer);
      };
      this.initLushu(map, points, this.mycaiId);
    },
    initLushu(map, points, mycaiId) {
      this.lushu = new BMapLib.LuShu(map, points, {
        defaultContent: mycaiId, //"从天安门到百度大厦"
        autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
        icon: new BMap.Icon(
          "http://developer.baidu.com/map/jsdemo/img/car.png",
          new BMap.Size(52, 26),
          {
            anchor: new BMap.Size(27, 13)
          }
        ),
        speed: this.speed,
        enableRotation: true, //是否设置marker随着道路的走向进行旋转
        landmarkPois: [
          {
            lng: 116.306954,
            lat: 40.05718,
            html: "加油站",
            pauseTime: 2
          }
        ]
      });
    },
    // 开始按钮
    start() {
      this.lushu.start();
    },
    // 暂停按钮
    stop() {
      this.lushu.stop();
    },
    pause() {
      this.lushu.pause();
    },
    getList() {
      getStorageTrajectory({
        deviceAddress: this.deviceAddress,
        startTime: "2020-12-29 00:00:00",
        endTime: "2020-12-31 00:00:00"
      }).then(res => {
        this.center = res.data.storageDataVos[0];
        this.path = res.data.storageDataVos;
        this.newList = res.data.storageDataVos[0];
        window.localStorage.setItem(
          "goPoints",
          JSON.stringify(res.data.storageDataVos)
        );
        if (this.newList.isRun == "0") {
          this.newList.isRun = "停止";
        } else {
          this.newList.isRun = "运动";
        }
        if (this.newList.locationType == "0") {
          this.newList.locationType = "wifi定位";
        } else {
          this.newList.locationType = "gps定位";
        }
        this.maxTime = res.data.maxTime;
      });
    },
    goback() {
      window.history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
#maps {
  width: 100%;
  height: 100%;
  position: relative;
}

.headers {
  position: absolute;
  top: calc(74 / 1080 * 100vw);
  left: calc(364 / 1920 * 100vw);
  span {
    color: #333;
    margin-left: calc(37 / 1920 * 100vw);
    margin-right: calc(20 / 1920 * 100vw);
  }
  .play {
    margin-left: calc(20 / 1920 * 100vw);
    background: #00b0ff;
    color: #fff;
  }
  .suspend {
    margin-left: calc(20 / 1920 * 100vw);
    background: #98c6da;
    color: #fff;
  }
  .continue {
    margin-left: calc(20 / 1920 * 100vw);
    background: #98c6da;
    color: #fff;
  }
  .electronic {
    margin-left: calc(84 / 1920 * 100vw);
    background: #00b0ff;
    color: #fff;
  }
  .active {
    background: #98c6da;
  }
  .isActive {
    background: #00b0ff;
  }
}

::v-deep .el-date-editor {
  background: #fff !important;
  .el-range-input {
    background: #fff !important;
  }
  .el-range-separator {
    background: #fff !important;
  }
}

::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(380 / 1920 * 100vw);
}

::v-deep .MessageBox {
  color: #333;
  position: absolute;
  top: calc(250 / 1080 * 100vh);
  right: calc(30 / 1920 * 100vw);
  width: calc(310 / 1920 * 100vw);
  height: calc(320 / 1920 * 100vw);
  background: #ffffff;
  border: calc(2 / 1920 * 100vw) solid #0e7771;
  border-radius: 8px;
  ul {
    height: calc(323 / 1080 * 100vh);
    margin: 0px 20px;
    li {
      margin: 19px 0;
      span:nth-child(2) {
        font-weight: bold;
      }
    }
  }
}
</style>
